import React, { useEffect } from "react";
// import FirstPage from "./FirstPage";
// import Navbar from "./Navbar";
// import Advantages from "./Advantages";
// import AnnabankValues from "./AnnabankValues";
// import ContactRequest from "./ContactRequest";
// import OurProduct from "./OurProduct";
// import Footer from "./Footer";

export default function Landing() {
  useEffect(() => {
    // Redireciona para a URL externa
    window.location.href = "https://landing.annabank.com.br/";
  }, []);
  return <></>;
}

// eslint-disable-next-line no-lone-blocks
{/*<>
      <Navbar />
      <FirstPage />
      <Advantages />
      <Devlab />
      <AnnabankValues />
      <ContactRequest />
      <OurProduct />
      <Footer />
      <WhatsAppWidget />
    </>*/}